import React, { useContext } from 'react'
import styled from 'styled-components'
import ButtonArrow from '@components/Button/Button.Arrow'
import Heading from '@components/Heading'
import Section from '@components/Section'
import ShapeShifter from '@components/ShapeShifter'
import Transitions from '@components/Transitions'
import Image from '@components/Image'
import LayoutHeroMobile from '@components/Layout/Layout.Hero.Mobile'
import { graphql, useStaticQuery } from 'gatsby'

import media from '@styles/media'
import Gradient from '@components/Gradient/Gradient'

function HomeHero() {
  const { homeHeroMobile } = useStaticQuery(imageQuery)

  return (
    <LayoutHeroMobile>
      <HomeHeroContainer id="home-hero">
        <Section>
          <ContentContainer>
            <TextContainer>
              <Transitions.CSS.FadeIn>
                <Heading.h1>
                  <Gradient>
                    We're an independent <Phone>design</Phone> studio building
                    impactful products.
                  </Gradient>
                </Heading.h1>
                <Mobile>
                  <ButtonArrow
                    as="button"
                    onClick={() =>
                      document.dispatchEvent(
                        new KeyboardEvent('keydown', { key: 'c' })
                      )
                    }
                    text="Get in touch"
                  />
                </Mobile>
              </Transitions.CSS.FadeIn>
            </TextContainer>
            <ShapeShifter />
          </ContentContainer>
        </Section>
        <Mobile>
          <MobileHeroImage>
            <Image src={homeHeroMobile.childImageSharp.fluid} />
          </MobileHeroImage>
        </Mobile>
        <ContactContainer>
          <Section>
            <Contact
              onClick={() =>
                document.dispatchEvent(
                  new KeyboardEvent('keydown', { key: 'c' })
                )
              }
            >
              Press <Key>C</Key> anywhere to contact us.
            </Contact>
          </Section>
        </ContactContainer>
      </HomeHeroContainer>
    </LayoutHeroMobile>
  )
}

export default HomeHero

const imageQuery = graphql`
  query HomeHeroQuery {
    homeHeroMobile: file(name: { regex: "/home-hero-mobile/" }) {
      childImageSharp {
        fluid(maxWidth: 2940, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const HomeHeroContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 140px;

  ${media.desktop`
    #mirror-mask {
      display: none;
    }
  `}

  ${media.tablet`
    padding-top: 0;
  `}
`

const Phone = styled.span`
  ${media.phablet_up`
    display: none;
  `}
`

const ContactContainer = styled.div`
  position: absolute;
  height: 90px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  background: transparent;
  display: block;
`

const TextContainer = styled.div`
  position: relative;
  z-index: 10;
  max-width: 530px;
  top: 10px;

  h1 {
    margin-bottom: 24px;
  }

  ${media.tablet`
    position: relative;
    top: 0;
    padding-bottom: 18vh;

    h1 {
      margin-bottom: 24px;
    }
  `}

  ${media.tablet`
    max-width: 384px;
    padding-bottom: 8vh;
  `}
`

const MobileHeroImage = styled.div`
  width: 100%;
  position: absolute;
  bottom: 80px;

  ${media.desktop_small_up`
    bottom: -300px;
  `}

  ${media.phablet`
    bottom: 10px;
  `}
`

const ContentContainer = styled.div`
  height: calc(100vh - 230px);
  min-height: 600px;
  padding-top: 10px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.phablet`
    height: calc(100vh - 180px);
    min-height: 100%;
    padding: 0;
    top: -40px;
  `};

  ${media.desktop_medium`
    min-height: 360px;
  `};

  @media screen and (max-height: 800px) {
    min-height: 360px;
  }

  @media screen and (max-height: 648px) {
    top: -60px;
  }
`

const Contact = styled.button`
  color: ${p => p.theme.colors.grey};
  font-weight: 600;
  transition: color 0.25s ease;

  &:hover {
    color: #fff;

    & > span:first-of-type {
      background: #fff;
    }
  }

  ${media.desktop`
    display: none;
  `};
`

const Key = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1px;
  color: #000;
  background: ${p => p.theme.colors.grey};
  font-size: 13px;
  width: 16px;
  height: 16px;
  border-radius: 2.5px;
  transition: background 0.25s ease;
`

const Mobile = styled.div`
  ${media.desktop_medium_up`
    display: none;
  `};
`
